import Layout from '../layout/index.vue'
import { defineAsyncComponent } from 'vue'

// type 访问功能类型， 为页面访问统计标记
const routes = [
  {
    path: '/home',
    component: Layout,
    redirect: '/home/knowledge',
    children: [{
      path: 'knowledge',
      name: 'Knowledge',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Knowledge" */ '../views/knowledge/index.vue')),
      meta: { title: '蓓加呵护', keepAlive: true, tabbar: true, type: 1 }
    }, {
      path: 'healthManage',
      name: 'HealthManage',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "HealthManage" */ '../views/healthManage/indexNew.vue')),
      meta: { title: '健康管理', keepAlive: false, tabbar: true, type: 2 }
    },
    {
      path: 'topic',
      name: 'TopicList',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Topic" */ '../views/topic/topicList.vue')),
      meta: { title: '主题活动', tabbar: true, type: 20 }
    },
    {
      path: 'userCenter',
      name: 'UserCenter',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "UserCenter" */ '../views/user/index.vue')),
      meta: { title: '个人中心', keepAlive: true, tabbar: true }
    }]
  },
  {
    path: '/user',
    name: 'User',
    component: Layout,
    children: [
      {
        path: 'info',
        name: 'UserInfo',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "UserInfo" */ '../views/user/info.vue')),
        meta: { title: '基础信息', keepAlive: true }
      }, {
        path: 'collect',
        name: 'UserCollect',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "UserCollect" */ '../views/user/collect.vue')),
        meta: { title: '我的收藏', keepAlive: true }
      }, {
        path: 'register',
        name: 'UserRegister',
        component: defineAsyncComponent(() => import (/* webpackChunkName: "UserRegister" */ '../views/user/register.vue')),
        meta: { title: '资料审核', keepAlive: true }
      }, {
        path: 'phone',
        name: 'UserPhone',
        component: defineAsyncComponent(() => import (/* webpackChunkName: "UserPhone" */ '../views/user/phone.vue')),
        meta: { title: '修改手机' }
      }, {
        path: 'login',
        name: 'UserLogin',
        component: defineAsyncComponent(() => import (/* webpackChunkName: "UserLogin" */ '../views/user/login.vue')),
        meta: { title: '登录' }
      }, {
        path: 'nickname',
        name: 'UserNickname',
        component: defineAsyncComponent(() => import (/* webpackChunkName: "UserNickname" */ '../views/user/nickname.vue')),
        meta: { title: '修改昵称' }
      },
      {
        path: 'update',
        name: 'UserUpdate',
        component: defineAsyncComponent(() => import (/* webpackChunkName: "UserUpdate" */ '../views/user/infoUpdate.vue')),
        meta: { title: '资料修改' }
      }
    ]
  },
  {
    path: '/project',
    name: 'Project',
    component: Layout,
    children: [
      {
        path: 'introduce',
        name: 'ProjectIntroduce',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "ProjectIntroduce" */ '../views/project/introduce.vue')),
        meta: { title: '项目介绍', keepAlive: true }
      }, {
        path: 'success',
        name: 'ProjectSuccess',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "ProjectSuccess" */ '../views/project/success.vue')),
        meta: { title: '提交结果' }
      }, {
        path: 'connection',
        name: 'ProjectConnection',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "ProjectConnection" */ '../views/project/connection.vue')),
        meta: { title: '联系我们', keepAlive: true }
      }, {
        path: 'agreement',
        name: 'ProjectAgreement',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "ProjectAgreement" */ '../views/project/agreement.vue')),
        meta: { title: '协议及声明', keepAlive: true }
      }, {
        path: 'agreementPrivacy',
        name: 'AgreementPrivacy',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "AgreementPrivacy" */ '../views/project/agreementPrivacy1.vue')),
        meta: { title: '隐私政策', keepAlive: true }
      }, {
        path: 'informedConsent',
        name: 'InformedConsent',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "InformedConsent" */ '../views/project/informedConsent.vue')),
        meta: { title: '知情同意书', keepAlive: true }
      }, {
        path: 'agreementSensitivity',
        name: 'AgreementSensitivity',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "AgreementSensitivity" */ '../views/project/agreementSensitivity.vue')),
        meta: { title: '个人敏感信息处理同意书', keepAlive: true }
      }, {
        path: 'userAgreement',
        name: 'UserAgreement',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "InformedConsent" */ '../views/project/userAgreement.vue')),
        meta: { title: '用户协议', keepAlive: true }
      }
    ]
  },
  {
    path: '/specialist',
    name: 'Specialist',
    component: Layout,
    children: [{
      path: 'videoList',
      name: 'SpecialistVideoList',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SpecialistVideoList" */ '../views/specialist/videoList.vue')),
      meta: { title: '一问医答', keepAlive: true, type: 5 }
    }, {
      path: ':id',
      name: 'SpecialistVideoDetail',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SpecialistVideoDetail" */ '../views/specialist/videoDetail.vue')),
      meta: { title: '视频详情', type: 6, share: true }
    }]
  },
  {
    path: '/offlineActivities',
    name: 'OfflineActivities',
    component: Layout,
    children: [{
      path: ':id',
      name: 'OfflineActivitiesDetail',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "OfflineActivitiesDetail" */ '../views/offlineActivities/index.vue')),
      meta: { title: '线下活动', keepAlive: true, type: 3, share: true }
    }, {
      path: 'sign/:id',
      name: 'OfflineActivitiesSign',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "OfflineActivitiesSign" */ '../views/offlineActivities/sign.vue')),
      meta: { title: '签到结果', type: 4 }
    }]
  },
  {
    path: '/article',
    name: 'Article',
    component: Layout,
    children: [{
      path: 'list',
      name: 'ArticleList',
      type: 7,
      component: defineAsyncComponent(() => import(/* webpackChunkName: "ArticleList" */ '../views/article/list.vue')),
      meta: { title: '科普文章', keepAlive: true, type: 7 }
    }, {
      path: ':id',
      name: 'ArticleDetail',
      // type: 8,9
      component: defineAsyncComponent(() => import(/* webpackChunkName: "ArticleDetail" */ '../views/article/detail.vue')),
      meta: { title: '文章详情', keepAlive: true, share: true }
    }]
  },
  {
    path: '/lecture',
    name: 'Lecture',
    component: Layout,
    children: [{
      path: 'list',
      name: 'LectureList',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "LectureList" */ '../views/lecture/list.vue')),
      meta: { title: '有医说医', keepAlive: true, type: 25 }
    }]
  },
  {
    path: '/question',
    name: 'Question',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "Question" */ '../views/question/indexNew.vue')),
    meta: { title: '蓓加呵护患者关爱项目调研', share: true, desc: '服务调研问卷来啦，欢迎大家动动手指填一填' }
  },
  {
    path: '/indicator',
    name: 'Indicator',
    component: Layout,
    children: [{
      path: 'record',
      name: 'IndicatorRecord',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "IndicatorRecord" */ '../views/indicator/record.vue')),
      meta: { title: '指标记录', keepAlive: false, type: 10 }
    }, {
      path: 'add',
      name: 'IndicatorAdd',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "IndicatorAdd" */ '../views/indicator/add.vue')),
      meta: { title: '添加记录', keepAlive: true }
    }, {
      path: 'choose',
      name: 'IndicatorChoose',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "IndicatorChoose" */ '../views/indicator/choose.vue')),
      meta: { title: '选择指标', keepAlive: false }
    }, {
      path: 'export',
      name: 'IndicatorExport',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "IndicatorExport" */ '../views/indicator/export.vue')),
      meta: { title: '导出指标', keepAlive: false }
    }]
  },
  {
    path: '/motion',
    name: 'Motion',
    component: Layout,
    children: [{
      path: 'index',
      name: 'MotionIndex',
      type: 12,
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MotionIndex" */ '../views/motion/index.vue')),
      meta: { title: '运动统计', keepAlive: false, type: 12 }
    }, {
      path: 'clock',
      name: 'MotionClock',
      type: 11,
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MotionClock" */ '../views/motion/clock.vue')),
      meta: { title: '运动打卡', keepAlive: false, type: 11 }
    }]
  },
  {
    path: '/medical',
    name: 'Medical',
    component: Layout,
    children: [{
      path: 'remind',
      name: 'MedicalRemind',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MedicalRemind" */ '../views/medical/remind.vue')),
      meta: { title: '用药提醒', keepAlive: false, type: 13 }
    }, {
      path: 'book',
      name: 'MedicalBook',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MedicalBook" */ '../views/medical/book.vue')),
      meta: { title: '药品说明书', keepAlive: true, type: 14 }
    }, {
      path: 'custom',
      name: 'MedicalCustom',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MedicalCustom" */ '../views/medical/custom.vue')),
      meta: { title: '用药提醒', keepAlive: false }
    }]
  },
  {
    path: '/subsequent',
    name: 'Subsequent',
    component: Layout,
    children: [{
      path: 'remind',
      name: 'SubsequentRemind',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SubsequentRemind" */ '../views/subsequent/remind.vue')),
      meta: { title: '复诊提醒', keepAlive: false, type: 16 }
    }]
  },
  {
    path: '/escalation',
    name: 'Escalation',
    component: Layout,
    children: [{
      path: 'index',
      name: 'EscalationIndex',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Escalation" */ '../views/escalation/index.vue')),
      meta: { title: '关于不良反应', keepAlive: true }
    }]
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "calculator" */ '../views/calculator/index.vue')),
    meta: { title: '医保计算器', keepAlive: false, type: 15 }
  },
  {
    path: '/transit',
    name: 'Transit',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "Transit" */ '../views/transit/index.vue')),
    meta: { title: '扫码详情', keepAlive: false }
  },
  {
    path: '/transit/line',
    name: 'TransitLine',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "Transit" */ '../views/transit/line.vue')),
    meta: { title: '直播详情', keepAlive: false }
  },
  {
    path: '/topic',
    name: 'Topic',
    component: Layout,
    children: [{
      path: ':id',
      name: 'TopicDetail',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SpecialistVideoList" */ '../views/topic/topicDetail.vue')),
      meta: { title: '话题详情', keepAlive: false, type: 21, share: true }
    },
    {
      path: 'commentList',
      name: 'CommentList',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SpecialistVideoList" */ '../views/topic/commentList.vue')),
      meta: { title: '评论详情', keepAlive: false }
    }] },
  {
    path: '/map',
    name: 'Map',
    component: Layout,
    children: [{
      path: 'list',
      name: 'MapList',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MapList" */ '../views/map/list.vue')),
      meta: { title: '用药地图', keepAlive: true, type: 23 }
    }, {
      path: 'view',
      name: 'MapView',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "MapView" */ '../views/map/view.vue')),
      meta: { title: '地图', keepAlive: false, type: 24 }
    }]
  },
  {
    path: '/search',
    name: 'Search',
    component: Layout,
    children: [{
      path: 'index',
      name: 'SearchIndex',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Search" */ '../views/search/index.vue')),
      meta: { title: '搜索结果', keepAlive: true }
    },
    {
      path: 'science',
      name: 'SearchScience',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Science" */ '../views/search/science.vue')),
      meta: { title: '用药阶段科普', keepAlive: true }
    }]
  },
  {
    path: '/upgradation',
    name: 'Upgradation',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "404" */ '../views/upgradation/index.vue')),
    meta: { title: '升级提示' }
  },
  {
    path: '/watch',
    name: 'Watch',
    component: Layout,
    children: [{
      path: ':id',
      name: 'WatchDetail',
      component: defineAsyncComponent(() => import(/* webpackChunkName: "SpecialistVideoDetail" */ '../views/watch/index.vue')),
      meta: { title: '有医说医', share: true, type: 26 }
    }]
  },
  {
    path: '/404',
    name: '404',
    component: defineAsyncComponent(() => import(/* webpackChunkName: "404" */ '../views/error/404.vue')),
    meta: { title: '抱歉，出错啦' }
  },
  { path: '/:pathMatch(.*)*', redirect: { name: '404' }}
]
export default routes
